import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, MouseEvent, useMemo, useState } from 'react';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { AiOutlineTag } from 'react-icons/ai';

import cx from 'classnames';
import moment from 'moment';
import currencyFormat from 'utils/curencyFormat';
import { getDrawStep } from 'utils/getDrawStep';

import { DrawSteps, IDraw } from 'models/IDraw';
import { ITicketBook } from 'models/ITicketBook';

import useClosesIn from '../../hooks/useClosesIn';
import Card, { CardProps } from '../Card/Card';
import Select from '../Select/Select';

import s from './DrawCard.module.scss';

export interface IDrawCardProps extends CardProps {
  draw: IDraw;
  quantityValue?: number;
  ticketBookValue?: ITicketBook;
  imgColumnClassName?: string;
  clubId?: number;
  isWinners?: boolean;

  onAddToCart?(value: IDraw, ev: MouseEvent<HTMLElement>): any;

  onTicketBookChange?(value: any): any;
}

const DrawCard: FC<IDrawCardProps> = ({
  draw,
  onAddToCart = () => {},
  onTicketBookChange = () => {},
  ticketBookValue,
  className,
  imgColumnClassName,
  isWinners,
}) => {
  const router = useRouter();
  const isInItsDrawPage = useMemo(
    () => draw.draw_no.toString() === router.query.draw_no,
    [draw.draw_no, router.query.draw_no],
  );
  const hasResults = useMemo(
    () => draw._workflow_current_step === DrawSteps.results,
    [draw],
  );
  const drawStep = useMemo(() => getDrawStep(draw), [draw]);
  const [drawIsClosed, setDrawIsClosed] = useState(drawStep !== DrawSteps.open);
  const closesIn = useClosesIn(moment(draw.close_datetime), () => {
    setDrawIsClosed(true);
  });

  const lgColDetails = useMemo(() => {
    if (!hasResults) {
      return isWinners ? 12 : 5;
    }
    return isWinners ? 12 : 8;
  }, [hasResults, isWinners]);

  return (
    <Card className={cx(className)}>
      <Card.Body
        className={cx('p-4 d-flex flex-column', {
          'p-0': isWinners,
        })}
      >
        <Row
          noGutters={isWinners}
          className={cx('align-content-start flex-grow-1', {
            'position-relative pb-6': isWinners,
          })}
        >
          <Col
            lg={!isWinners ? 3 : 12}
            className={cx('d-flex mb-0', {
              'flex-lg-column': !isWinners,
              'p-lg-0 m-lg-0': isWinners,
            })}
          >
            {draw?.draw_hero_images?.[0]?.image?.url && (
              <img
                loading="lazy"
                src={draw.draw_hero_images[0].image.url}
                alt={`Draw ${draw.draw_no}`}
                className={cx(
                  'overflow-hidden mt-n4 mx-n4 d-block max-w-initial object-fit-cover rounded-t rounded-sm-lg',
                  s.drawCardImage,
                  imgColumnClassName,
                  { 'mt-lg-0 mx-lg-0 w-lg-100': !isWinners },
                )}
              />
            )}
          </Col>
          <Col
            lg={lgColDetails}
            className={cx('d-flex flex-column', {
              'p-lg-0': isWinners,
            })}
          >
            <div
              className={cx('w-100', {
                'mt-lg-0': !isWinners,
              })}
              style={{ marginTop: '-14px' }}
            >
              <strong
                className={cx(
                  'text-center text-white px-3 py-1 bg-primary rounded-pill mt-n3',
                  {
                    'px-1': isWinners,
                  },
                )}
              >
                Draw {draw.draw_no}
              </strong>
            </div>
            <h4
              className={cx('large-body pt-6', {
                'pt-lg-3': !isWinners,
                'pt-lg-5': isWinners,
              })}
            >
              {draw.first_prize}
            </h4>
            <p className="w-100 flex-grow-1">
              First prize of {currencyFormat(draw.total_first_prize_value)}.{' '}
              {ticketBookValue?.description &&
                `Includes ${ticketBookValue?.description}.`}
            </p>

            {!hasResults && !isInItsDrawPage && (
              <Link href={`/lottery/${draw.draw_no}`} passHref prefetch={false}>
                <a
                  className={cx('text-black text-button-link', {
                    'pb-lg-3': !isWinners,
                  })}
                >
                  Find out more
                </a>
              </Link>
            )}
          </Col>
          {hasResults && (
            <Link href={`/lottery/${draw.draw_no}`} prefetch={false}>
              <a
                className={cx('text-black text-button-link', {
                  'pb-lg-3': !isWinners,
                  'position-absolute bottom-0 left-0': isWinners,
                })}
              >
                View full results
              </a>
            </Link>
          )}
          {!hasResults && (
            <Col lg={!isWinners ? 4 : 1}>
              <Card className="bg-light-grey no-shadow">
                <Card.Body
                  className={cx('p-4', {
                    'p-lg-0': isWinners,
                  })}
                >
                  {ticketBookValue?.ticket_book_tag?.label && (
                    <div className="mb-3">
                      <Badge className="p-2" variant="danger">
                        <AiOutlineTag className="mr-2 my-0" color="white" />

                        <span className="font-weight-normal">
                          {ticketBookValue.ticket_book_tag?.label}
                        </span>
                      </Badge>
                    </div>
                  )}

                  <div>
                    {!hasResults && (
                      <div className="d-flex align-items-center mb-2">
                        {(draw.ticket_books || []).length > 1 ? (
                          <Select
                            className="flex-grow-1 mr-3"
                            controlStyles={{
                              borderColor: 'var(--primary)',
                              borderWidth: '3px',
                              borderRadius: '10px',
                            }}
                            dropDownIndicatorStyles={{
                              color: 'var(--primary)',
                            }}
                            singleValueStyles={{
                              color: 'var(--primary)',
                              fontSize: '1.3em',
                            }}
                            name="quantity"
                            options={draw.ticket_books || []}
                            labelAttribute="ticket_book_dollar_amount"
                            getOptionLabel={(value: any) =>
                              `$${value.ticket_book_dollar_amount}`
                            }
                            isClearable={false}
                            onChange={onTicketBookChange}
                            value={ticketBookValue}
                          />
                        ) : (
                          <Badge variant="outline-primary flex-grow-1 mr-3 py-2">
                            <h3 className="text-primary text-center">
                              ${ticketBookValue?.ticket_book_dollar_amount}
                            </h3>
                          </Badge>
                        )}
                        <div className="text-center text-primary font-weight-bold">
                          <div className="m-0 h3 font-weight-bold">
                            {ticketBookValue?.ticket_qty}
                          </div>

                          <div className="mt-n2">Tickets</div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mt-3">
                    <Button
                      disabled={drawIsClosed}
                      className={cx('w-100 py-2 flex-grow-1', {
                        'rounded-lg': !isWinners,
                      })}
                      size="sm"
                      variant="danger"
                      data-cy="draw-card-add-to-cart"
                      onClick={e => onAddToCart(draw, e)}
                    >
                      Add to Cart
                    </Button>
                  </div>
                </Card.Body>
              </Card>
              {!drawIsClosed && (
                <div
                  className="my-0 mx-auto py-1 mx-25 bg-white shadow-sm"
                  style={{ borderRadius: '0px 0px 16px 16px', width: '90% ' }}
                >
                  <p className="text-primary text-center font-weight-bold ">
                    Closes in <span className="text-danger">{closesIn}</span>
                  </p>
                </div>
              )}

              <div
                className={cx(
                  'd-flex flex-row align-items-center mb-0 mb-md-2 pt-3',
                  {
                    'flex-lg-column align-items-lg-start py-lg-0': !isWinners,
                  },
                )}
              >
                {drawIsClosed && drawStep === DrawSteps.close && (
                  <strong className="text-danger">CLOSED</strong>
                )}
                {drawStep === DrawSteps.results && (
                  <strong className="text-primary d-block">
                    Drawn on {moment(draw.draw_datetime).format('DD/MM/YYYY')}
                  </strong>
                )}
              </div>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DrawCard;
