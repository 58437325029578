import { ComponentProps, FC } from 'react';
import ReactSelect from 'react-select';

export type SelectProps = ComponentProps<typeof ReactSelect> & {
  getOptionLabel?(value: any): string;
  rounded?: boolean;
  controlStyles?: any;
  singleValueStyles?: any;
  dropDownIndicatorStyles?: any;
};

const Select: FC<SelectProps> = ({
  labelAttribute = 'name',
  valueAttribute = 'id',
  isClearable = true,
  controlStyles = {},
  singleValueStyles = {},
  dropDownIndicatorStyles = {},
  rounded = false,
  getOptionLabel,
  ...props
}) => {
  return (
    <ReactSelect
      styles={{
        menu: provided => ({ ...provided, zIndex: 1000 }),
        singleValue: base => {
          return {
            ...base,
            fontWeight: 'bold',
            ...singleValueStyles,
          };
        },
        dropdownIndicator: base => {
          return {
            ...base,
            ...dropDownIndicatorStyles,
          };
        },
        control: (defaultControlProps: any) => {
          return {
            ...defaultControlProps,
            minHeight: '50px',
            paddingLeft: '10px',
            ...(rounded ? { borderRadius: '25px' } : {}),
            borderWidth: '3px',
            ...controlStyles,
          };
        },
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
      isClearable={isClearable}
      getOptionLabel={getOptionLabel || ((value: any) => value[labelAttribute])}
      getOptionValue={(value: any) => value[valueAttribute]}
      {...props}
    />
  );
};

export default Select;
