import Link from 'next/link';
import { FC } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { RowProps } from 'react-bootstrap/Row';

import cx from 'classnames';

import Card from 'components/Card/Card';
import { IPersonaSupportiveMessage } from 'models/IPersonaSupportiveMessage';

import s from './SupportiveMessageRow.module.scss';

interface IProps extends RowProps {
  supportiveMessage: IPersonaSupportiveMessage;
  isChampionClub?: boolean;
}

const SupportiveMessageRow: FC<IProps> = ({
  supportiveMessage,
  isChampionClub,
  ...props
}) => {
  return (
    <Row {...props} noGutters>
      {supportiveMessage.image_asset?.media_file.url && (
        <Col lg={6} className="pb-0 pb-lg-4 position-relative px-4 px-lg-0">
          <Card
            className={cx(
              s.imageCard,
              s.cardMinHeight,
              'overflow-hidden mr-0 mr-lg-n3',
            )}
          >
            <img
              loading="lazy"
              className="object-fit-cover w-100 h-100 d-block position-absolute"
              src={supportiveMessage.image_asset?.media_file.url}
              alt={supportiveMessage.image_asset?.name}
            />
          </Card>
        </Col>
      )}
      <Col lg={6} className="d-flex flex-column pt-0 pt-lg-4 mt-n4 mt-lg-0">
        <Card
          className={cx(
            s.cardMinHeight,
            {
              'bg-secondary': !isChampionClub,
              'bg-dark-primary': isChampionClub,
            },
            'flex-grow-1 ml-0 ml-lg-n3 rounded-md-none p-5 pt-7 pt-lg-5 pl-lg-7 d-flex flex-column justify-content-center no-shadow',
          )}
        >
          <h2
            className={cx({
              'text-black': !isChampionClub,
              'text-gold': isChampionClub,
            })}
          >
            {supportiveMessage.heading}
          </h2>
          {supportiveMessage.sub_heading && (
            <p
              className={cx(
                {
                  'text-black': !isChampionClub,
                  'text-gold': isChampionClub,
                },
                'mt-3 large-body font-weight-semi-bold',
              )}
            >
              {supportiveMessage.sub_heading}
            </p>
          )}
          {supportiveMessage.button_label && supportiveMessage.button_link && (
            <Link prefetch={false} href={supportiveMessage.button_link}>
              <Button
                className={cx(
                  {
                    'text-danger': !isChampionClub,
                    'text-white': isChampionClub,
                  },
                  'text-left mt-3 font-weight-bold px-0',
                )}
                as="a"
                variant="link"
              >
                {supportiveMessage.button_label}
              </Button>
            </Link>
          )}
        </Card>
      </Col>
    </Row>
  );
};
export default SupportiveMessageRow;
