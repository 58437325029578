import { useEffect, useState } from 'react';

import moment from 'moment';
import { $enum } from 'ts-enum-util';

export enum MomentTimes {
  days = 'asDays',
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'seconds',
}
const calculateClosesIn = (
  closingDate: moment.Moment,
  onlyTimes: MomentTimes[] = $enum(MomentTimes).getValues(),
): string | undefined => {
  const diff = closingDate.diff(moment());
  if (diff <= 0) {
    return undefined;
  }
  const duration = moment.duration(diff);
  const arrayOfTimeString = $enum(MomentTimes)
    .getValues()
    .reduce((acc: string[], current) => {
      const intTimeValue = Math.floor(duration[current]());
      if (onlyTimes.includes(current) && intTimeValue) {
        return [
          ...acc,
          `${intTimeValue}${current
            .replace(/^as/, '')
            .substr(0, 1)
            .toLowerCase()}`,
        ];
      }
      return acc;
    }, []);

  return arrayOfTimeString.join(' ');
};

const useClosesIn = (
  closingDate: moment.Moment,
  onClose: () => any = () => {},
  onlyTimes?: MomentTimes[],
) => {
  const [closesIn, setClosesIn] = useState(calculateClosesIn(closingDate));

  useEffect(() => {
    setClosesIn(calculateClosesIn(closingDate, onlyTimes));
    if (!closesIn) {
      return () => {};
    }
    const interval = setInterval(() => {
      setClosesIn(calculateClosesIn(closingDate, onlyTimes));
      if (!closesIn) {
        onClose();
        if (interval) {
          clearInterval(interval);
        }
      }
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [closingDate]);

  return closesIn;
};

export default useClosesIn;
