import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

import cx from 'classnames';
// eslint-disable-next-line import/no-unresolved
import { Except } from 'type-fest';

import Card from 'components/Card/Card';
import GradientCard, {
  GradientCardProps,
} from 'components/GradientCard/GradientCard';

export interface IGradientCardWithImage
  extends Except<GradientCardProps, 'color'> {
  imageUrl?: string;
  imageAlt?: string;
  imgProps?: any;
  color?: string;
}

const GradientCardWithImage: FC<IGradientCardWithImage> = ({
  children,
  imageUrl,
  imageAlt,
  className,
  imgProps = {},
  ...props
}) => {
  return (
    <GradientCard className={cx('position-relative', className)} {...props}>
      <Card.Body className="p-6 d-flex">
        <Row className="flex-grow-1">
          {imageUrl && (
            <Col md="auto" className="flex-grow-0">
              <figure className="position-relative ml-auto mr-auto mt-n10 mt-md-0 ml-md-n9 ml-xl-n10 d-block">
                <img
                  loading="lazy"
                  {...imgProps}
                  className={cx(
                    imgProps.className,
                    'object-fit-contain d-block mx-auto',
                  )}
                  src={imageUrl}
                  alt={imageAlt}
                  width={100}
                  height={100}
                />
              </figure>
            </Col>
          )}
          <Col className="d-flex flex-column justify-content-center">
            {children}
          </Col>
        </Row>
      </Card.Body>
    </GradientCard>
  );
};

export default GradientCardWithImage;
