import { FC, useEffect, useState } from 'react';

import isIE from 'utils/isIE';

import Card, { CardProps } from 'components/Card/Card';

export interface GradientCardProps extends CardProps {
  className?: string;
  loading?: boolean;
  color?: string;
}
const DEFAULT_PRIMARY_COLOR = 'var(--primary)';
const GradientCard: FC<GradientCardProps> = ({
  children,
  style = {},
  color = DEFAULT_PRIMARY_COLOR,
  ...props
}) => {
  const [finalColor, setFinalColor] = useState(color);
  useEffect(() => {
    if (color === DEFAULT_PRIMARY_COLOR && isIE()) {
      setFinalColor('#1055a0');
    }
  }, [color]);
  return (
    <Card
      style={{
        ...style,
        backgroundColor: finalColor,
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.3), transparent)`,
      }}
      {...props}
    >
      {children}
    </Card>
  );
};

export default GradientCard;
